/* .slider-container-custom {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 500px;
  }
  
  .slide-custom  {
    display: none;
    width: 100%;
    height: 100%;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .slide-custom.activeBackground {
    display: block;
    opacity: 1;
  }
  
  .overlay_background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    transition: transform 0.5s ease-in-out;
    display: flex;
    justify-content: start;
    flex-direction: column;
    gap: 20px;
  }
  .overlay_background  h1{
    width: 100%;
  }
  .overlay_background  p{
    width: 60%;
    font-size: 19px;
    color: #fff;
  }
  .overlay_background_button{
    display: flex;
    gap: 30px;
  }


  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    color: #fff;
    border: none;
    padding: 15px 20px;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 5px;
    border: 1px solid white;
  }

  .next {
    right: 0;
  }

  @media only screen and (max-width: 779px){
.overlay_background p{
  width: 100%;
  font-size: 16px;
  height: 200px;
  overflow: hidden;
}
  }


.slide-transition-enter {
    opacity: 0;
    transform: translateY(-100%);
  }
  
  .slide-transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
  }
  
  .slide-transition-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .slide-transition-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 500ms, transform 500ms;
  } */



  /* ------------------------------------------------ */


  /* Slider.css */
.slider-container-custom {
  position: relative;
  width: 100%;
  height: 760px;
  overflow: hidden;
}

.slide-custom {
  width: 100%;
  height: 100%;
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  filter: contrast(1.25);

}

.slide-custom.activeBackground {
  opacity: 1;
}

.overlay_background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  transition: transform 0.5s ease-in-out;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  filter: contrast(1.25);

}

.overlay_background  h1{
  width: 100%;
}
.overlay_background  p{
  width: 60%;
  font-size: 19px;
  color: #fff;
}


h1, p.text {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.slide-custom.activeBackground h1, .slide-custom.activeBackground p.text {
  opacity: 1;
  transform: translateY(0);
}

.slide-custom.activeBackground {
  animation: slide-up 1s forwards;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.prev, .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 15px 20px;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 5px;
  border: 1px solid white;
  filter: contrast(1.25);

}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

  
  