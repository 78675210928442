.cookie-policy-article {
    /* max-width: 800px; */
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  
  .cookie-policy-article h1 {
    color: #333;
  }
  
  .cookie-policy-article strong {
    font-weight: bold;
  }
  
  .cookie-policy-article h2 {
    color: #555;
    margin-top: 20px;
  }
  
  .cookie-policy-article p {
    color: #666;
    margin-bottom: 15px;
  }
  
  .cookie-policy-article ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .cookie-policy-article li {
    margin-bottom: 5px;
  }
  
  .cookie-policy-article a {
    color: #007BFF;
    text-decoration: underline;
  }
  
  .cookie-policy-article a:hover {
    color: #0056b3;
  }
  
  .cookie-policy-article button {
    background-color: #007BFF;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cookie-policy-article button:hover {
    background-color: #0056b3;
  }
  