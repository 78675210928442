/* src/components/CookieConsentModal.css

.cookie-consent-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .cookie-consent-modal-content {
    background: #2d2d2d;
    color: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .cookie-link {
    color: #1a73e8;
    text-decoration: none;
  }
  
  .cookie-link:hover {
    text-decoration: underline;
  }
  
  .cookie-consent-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  
  .cookie-button {
    background-color: #1a73e8;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    text-align: center;
  }
  
  .cookie-button:hover {
    background-color: #155cb0;
  }
  
  .cookie-preferences {
    margin-top: 20px;
  }
  
  .cookie-preference {
    background: #3a3a3a;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .cookie-preference-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }
  
  .always-active {
    color: #1a73e8;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #1a73e8;
  }
  
  input:checked + .slider:before {
    transform: translateX(14px);
  }
   */


   /* src/components/CookieConsentModal.css */

.cookie-consent-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .cookie-consent-modal-content {
    background: #2d2d2d;
    color: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 33%;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .cookie-link {
    color: #1a73e8;
    text-decoration: none;
  }
  
  .cookie-link:hover {
    text-decoration: underline;
  }
  
  .cookie-consent-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  
  .cookie-button {
    background-color: #1a73e8;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    text-align: center;
  }
  
  .cookie-button:hover {
    background-color: #155cb0;
  }
  
  .cookie-preferences {
    margin-top: 20px;
  }
  
  .cookie-preference {
    background: #3a3a3a;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .cookie-preference-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }
  
  .always-active {
    color: #3db5ff;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #1a73e8;
  }
  
  input:checked + .slider:before {
    transform: translateX(14px);
  }
  
  .cookie-consent-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  
  .cookie-consent-buttons > .cookie-button {
    flex: 1;
  }
  
  .cookie-consent-buttons > .cookie-button:nth-child(2),
  .cookie-consent-buttons > .cookie-button:nth-child(3) {
    margin-right: 10px;
  }
  