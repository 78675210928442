/* src/components/CookieConsent.css */

.cookie-consent {
    position: fixed;
    bottom: 20px;
    left: 20px;
    /* right: 20px; */
    max-width: 400px;
    margin: auto;
    background-color: #2d2d2d;
    color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
     align-items: flex-start; 
    z-index: 1000;
  }
  
  .cookie-consent-content {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .cookie-icon {
    font-size: 40px;
    margin-right: 10px;
  }
  
  .cookie-link {
    color: #1a73e8;
    text-decoration: none;
  }
  
  .cookie-link:hover {
    text-decoration: underline;
  }
  
  .cookie-consent-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  .button-row {
    display: flex;
    gap: 10px;
    width: 100%;
  }
  
  .cookie-button {
    background-color: #1a73e8;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  flex: 1;

  }
  
  .cookie-button:hover {
    background-color: #155cb0;
  }
  
  .cookie-button:last-child {
    width: 100%;
  }