	/*
  	Flaticon icon font: Flaticon
  	Creation date: 29/07/2017 07:53
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-investment:before { content: "\f100"; }
.flaticon-bar-chart:before { content: "\f101"; }
.flaticon-business:before { content: "\f102"; }
.flaticon-remove:before { content: "\f103"; }
.flaticon-people:before { content: "\f104"; }
.flaticon-suitcase:before { content: "\f105"; }
.flaticon-money:before { content: "\f106"; }
.flaticon-graphic:before { content: "\f107"; }
.flaticon-graph:before { content: "\f108"; }